<template>
  <div>
    <b-container
      fluid
    >
      <b-table-filter
        :items="items"
        :fields="fields"
        title="Danh Sách Học Viên"
        :totalRows="rowsLength"
        :currentPage="currentPage"
        :perPage="perPage"
        :filterOn="filterOn"
        :filter="filter"
        @remove-act="removeItem"
      >
        <template #filterAdvanced>
          <b-row>
            <b-col cols="7">
              <b-row no-gutters>
                <b-col cols="4">
                  <b-form-group
                    label="Tìm Tên Học Viên"
                  >
                    <div class="ft-class-list-opt">
                      <b-form-input @change="filterAdvanced('student', $event)"  placeholder="Tìm..."/>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group
                    label="Tìm Email"
                  >
                    <div class="ft-class-list-opt">
                      <b-form-input @change="filterAdvanced('email', $event)" placeholder="Tìm..."/>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group
                    label="Tìm Số Điện Thoại"
                  >
                    <div class="ft-class-list-opt">
                      <b-form-input @change="filterAdvanced('phone', $event)" placeholder="Tìm..."/>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="4" offset="1">
              <b-row no-gutters>
                <b-col>
                  <div class="ft-class-list-opt">
                    <b-form-group
                      label="Từ "
                    >
                      <b-form-datepicker
                        v-model="filterDateFrom"
                        @input="columnFilter('tu_ngay')"
                        placeholder="Từ Ngày"
                        :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                        :date-disabled-fn="dateDisabled"
                      />
                    </b-form-group>
                  </div>
                </b-col>
                <b-col>
                  <div class="ft-class-list-opt">
                    <b-form-group
                      label="Đến"
                    >
                      <b-form-datepicker
                        v-model="filterDateTo"
                        @input="columnFilter('den_ngay')"
                        placeholder="Đến Ngày"
                        :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                        :date-disabled-fn="dateDisabled"
                      />
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>
        <template #filterTable>
          <td style="width: 15%">
              <b-form-input size="sm"
                class="ft-search-form-them-khoa-hoc"
                placeholder="Tìm Khóa Học"
                @keyup="columnFilter('course')"
                v-model="filterKhoaHoc"
              />
          </td>
          <td style="width: 15%">
              <b-form-input size="sm"
                class="ft-search-form-them-khoa-hoc"
                placeholder="Tìm Lớp Học"
                @keyup="columnFilter('class_name')"
                v-model="filterLopHoc"
              />
          </td>
          <td style="width: 15%">
              <b-form-input size="sm"
                class="ft-search-form-them-khoa-hoc"
                placeholder="Tìm Giáo Viên"
                @keyup="columnFilter('teacher')"
                v-model="filterGiaoVien"
              />
          </td>
          <td style="width: 15%">
              <b-form-input size="sm"
                class="ft-search-form-them-khoa-hoc"
                placeholder="Tìm Trợ Giảng"
                @keyup="columnFilter('teacher_at')"
                v-model="filterTeacherAt"
              />
          </td>
          <td>
              <b-form-datepicker  size="sm"
                v-model="filterDateFrom"
                @input="columnFilter('tu_ngay')"
                placeholder="Từ Ngày"
                :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
              />
          </td>
          <td>
              <b-form-datepicker size="sm"
                v-model="filterDateTo"
                @input="columnFilter('den_ngay')"
                placeholder="Đến Ngày"
                :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                :date-disabled-fn="dateDisabled"
              />
          </td>
        </template>
      </b-table-filter>
    </b-container>
  </div>
</template>

<script>
import {
  BFormGroup, BButton, BCol, BRow, BFormInput, BFormDatepicker, BContainer, 
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { formatDate } from '@/@core/utils/filter'
import BTableFilter from '@/@core/components/datatable/BTableFilter.vue'
import VueSelect from 'vue-select'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BCol,
    BRow,
    VueSelect,
    FeatherIcon,
    BTableFilter,
    BContainer,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      fields: [
        {
          label: 'id',
          key: 'ID',
        },
        {
          label: 'Họ Tên Học Viên',
          key: 'fullName',
        },
        {
          label: 'Giới Tính',
          key: 'gender',
        },
        {
          label: 'Số Điện Thoại',
          key: 'phone',
        },
        {
          label: 'Email',
          key: 'email',
        },
        {
          label: 'Địa Chỉ',
          key: 'address',
        },
        {
          label: 'Số Khóa Đang Học',
          key: 'courseCount',
        },
        {
          label: 'Ngày Đăng Ký',
          key: 'createdAt',
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filterKhoaHoc: null,
      filterLopHoc: null,
      filterGiaoVien: null,
      filterTeacherAt: null,
      filter: null,
      filterOn: [], // danh sách cột
      count: 0,
      filterDateFrom: null,
      filterDateTo: null,
      selected: [],
      items: [
        {
          id: 1,
          phone: '096 998 5002',
          gender: 'male',
          fullName: 'Quốc DŨng',
          email: 'XXXXX@gmail.com',
          courseCount: 20,
          createdAt: 1658925621,
          address: '',
        }
      ],
    }
  },
  mounted() {
    document.title = `Quản lý Lớp | ${this.router.meta.pageTitle}`
  },
  computed: {
    rowsLength() {
      return this.items.length;
    },
  },
  methods: {
    removeItem(id, k) {
      console.log('remove', id, k)
    },
    filterAdvanced(t, $event) {
    },
    dateDisabled(ymd, date) {
      const day = date.getDate()
      return day < new Date(new Date(this.filterDateFrom).getDate())
    },
    columnFilter(t) {
      this.filterOn = [t]
      // eslint-disable-next-line default-case
      switch (t) {
        case 'course' :
        {
          this.filter = this.filterKhoaHoc
        }
        break;
        case 'class_name' :
        {
          this.filter = this.filterLopHoc
        }
        break;
        case 'teacher' :
        {
          this.filter = this.filterGiaoVien
        }
        break;
        case 'teacher_at' :
        {
          this.filter = this.filterTeacherAt
        }
        break;
      }
    },
    onFiltered(filteredItems) {
      // console.log('onFilter', filteredItems)
      // this.items = filteredItems
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onRowSelected(rows) {
      this.count = rows.length
      this.selected = rows
    },
  },
}
</script>

<style>
.ft-class-list-opt{
  margin-right: 10px;
}
</style>